import { useEffect, useState } from 'react';
import { TextField, Button, Grid, Typography, Box, CircularProgress, Container } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnLink, BtnNumberedList, BtnStrikeThrough, BtnUnderline, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/il8n';

interface SMTPConfig {
    host: string;
    port: number;
    auth: IAuth;
    from: number;
    to: number;
}

interface IAuth {
    user: string;
    pass: string;
}

interface EmailTemplate {
    subject: string;
    body: string;
}

interface Props { }

const SMTPForm: React.FC<Props> = ({ }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [smtpConfig, setSmtpConfig] = useState<SMTPConfig>({ host: 'smtp.gmail.com', port: 465, auth: { user: '', pass: '' }, from: 1, to: 100 });
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({ subject: '', body: '' });
    const [excelFile, setExcelFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const handleAuthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "user" || name === "pass")
            setSmtpConfig((prevConfig) => ({
                ...prevConfig,
                auth: {
                    ...prevConfig.auth,
                    [name]: value,
                },
            }));
        else
            setSmtpConfig((prevConfig) => ({
                ...prevConfig,
                [name]: value
            }));
    };

    useEffect(() => {
        const isLogin = localStorage.getItem("saas-email");
        if (isLogin !== "true") {
            navigate("/");
        }
    }, [navigate]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEmailTemplate({ ...emailTemplate, [name]: value });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            toast(t('file_upload_success'));
            setExcelFile(e.target.files[0]);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (!smtpConfig.host || !smtpConfig.port || !smtpConfig.auth.user || !smtpConfig.auth.pass) {
            toast.error(t('fill_all_smtp_fields'));
            setLoading(false);
            return;
        }

        if (!emailTemplate.subject || !emailTemplate.body) {
            toast.error(t('fill_email_subject_body'));
            setLoading(false);
            return;
        }

        if (!excelFile) {
            toast.error(t('upload_excel_file'));
            setLoading(false);
            return;
        }

        onSubmit(smtpConfig, emailTemplate, excelFile);
    };

    const onSubmit = (smtpConfig: SMTPConfig, emailTemplate: EmailTemplate, excelFile: File) => {
        const reader = new FileReader();
        reader.onload = async () => {
            const base64Excel = reader.result as string;
            try {
                toast(t('process_duration'));
                const url = 'https://bulkemailserver.daddyhelp.me/send-emails';
                // const url = 'http://localhost:3001/send-emails';
                await axios.post(url, {
                    smtpConfig,
                    emailTemplate,
                    excelFilePath: base64Excel,
                }).then(({ data }) => {
                    setLoading(false);
                    setSmtpConfig({ host: '', port: 465, auth: { user: '', pass: '' }, from: 1, to: 100 });
                    setEmailTemplate({ subject: '', body: '' });
                    setExcelFile(null);
                    alert(data.message);
                }).catch((err: any) => {
                    console.error(err);
                    setLoading(false);
                    const response = err.response as { data?: { message?: string } };
                    alert(response?.data?.message);
                });
            } catch (error: any) {
                setLoading(false);
                const response = error.response as { data?: { message?: string } };
                alert(response?.data?.message);
            }
        };
        reader.readAsDataURL(excelFile);
    };

    useEffect(() => {
        const handleStatus = (_: any, message: string) => {
            if (!message) return;
            toast.success(message, { toastId: 1909 });
            setSmtpConfig({ host: '', port: 465, auth: { user: '', pass: '' }, from: 1, to: 100 });
            setEmailTemplate({ subject: '', body: '' });
            setExcelFile(null);
            setLoading(false);
        };
    }, []);


    return (
        <Container>
            <Grid display={'flex'} justifyContent={'end'} alignItems={'self-end'}>
                <Button
                    onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'ko' : 'en')}
                    // fullWidth
                    variant="text"
                    sx={{ mt: 2 }}
                >
                    {i18n.language === 'en' ? '한국어' : 'English'}
                </Button>
            </Grid>
            {/* Video Tutorial Section */}
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                    {t('tutorial_video')}
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                paddingBottom: '56.25%', // 16:9 aspect ratio
                                height: 0,
                                overflow: 'hidden',
                                maxWidth: '100%',
                                backgroundColor: '#000',
                                borderRadius: 2,
                            }}
                        >
                            <iframe
                                src="https://www.youtube.com/embed/0H7PCiY9jRw"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Tutorial Video"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <ToastContainer />
                <Grid display={'flex'}>
                    <Typography variant="h6" gutterBottom>
                        {t('smtp_config')}
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="host"
                            label={t('smtp_host')}
                            fullWidth
                            disabled={loading}
                            value={smtpConfig.host}
                            onChange={handleAuthChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="port"
                            label={t('smtp_port')}
                            type="number"
                            fullWidth
                            disabled={loading}
                            value={smtpConfig.port}
                            onChange={handleAuthChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="user"
                            label={t('email_user')}
                            fullWidth
                            disabled={loading}
                            placeholder={t('email_placeholder')}
                            value={smtpConfig.auth.user}
                            onChange={handleAuthChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="pass"
                            label={t('password')}
                            type="password"
                            fullWidth
                            disabled={loading}
                            value={smtpConfig.auth.pass}
                            onChange={handleAuthChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="from"
                            label={t('from')}
                            type="number"
                            fullWidth
                            disabled={loading}
                            value={smtpConfig.from}
                            onChange={handleAuthChange}
                        />
                        <p style={{ fontStyle: 'italic', color: '#555', marginTop: '10px' }}>
                            {t('from_hint')}
                            {/* index no of email in excel sheet from where you want to begin sending email */}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="to"
                            label={t('to')}
                            type="number"
                            fullWidth
                            disabled={loading}
                            value={smtpConfig.to}
                            onChange={handleAuthChange}
                        />
                        <p style={{ fontStyle: 'italic', color: '#555', marginTop: '10px' }}>
                            {t('to_hint')}
                            {/* index no of email on excel sheet uptil where you want to send email. */}
                        </p>
                    </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    {t('email_template')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="subject"
                            label={t('email_subject')}
                            fullWidth
                            disabled={loading}
                            value={emailTemplate.subject}
                            onChange={handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <QuillEditor
                        value={emailTemplate.body}
                        onChange={body => setEmailTemplate({ ...emailTemplate, body })}
                    /> */}
                        {/* <EditorProvider
                    /> */}
                        <EditorProvider>
                            <Editor
                                aria-placeholder={t('editor_placeholder')}
                                containerProps={{ style: { resize: 'vertical' } }} value={emailTemplate.body} onChange={(e: any) => setEmailTemplate({ ...emailTemplate, body: e.target.value })}>
                                <Toolbar>
                                    <BtnBold title={t('bold')} />
                                    <BtnItalic title={t('italic')} />
                                    <BtnUnderline title={t('underline')} />
                                    <BtnStrikeThrough title={t('strikethrough')} />
                                    <BtnBulletList title={t('bullet_list')} />
                                    <BtnNumberedList title={t('numbered_list')} />
                                    <BtnLink title={t('insert_link')} />
                                    <BtnClearFormatting title={t('clear_formatting')} />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </Grid>
                </Grid>
                <Grid item spacing={2}>

                    <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        {t('upload_recipients')}
                    </Typography>
                    <Grid container display="flex" alignItems="center" mt={2}>

                        <Grid item display={'flex'} md={6}>
                            <Button variant="contained" component="label" sx={{ mr: 2 }}>
                                {t('upload_file')}
                                <input disabled={loading} type="file" hidden accept=".xlsx, .xls" onChange={handleFileChange} />
                            </Button>
                            {excelFile && (
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    {t('file_uploaded')}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item md={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    const sampleFilePath = `${process.env.PUBLIC_URL}/sample.xlsx`;
                                    const link = document.createElement('a');
                                    link.href = sampleFilePath;
                                    link.download = 'sample.xlsx';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}
                            >
                                {t('download_sample_file')}
                            </Button>
                        </Grid>

                        <Box sx={{ mt: 3 }}>
                            <Button type="submit" variant="contained" disabled={loading} color="primary">
                                {loading ? <CircularProgress size={24} /> : t('send_emails')}
                            </Button>
                            {loading && t('processing_time')}
                        </Box>

                    </Grid>
                </Grid>
            </Box >
        </Container >
    );
};

export default SMTPForm;
