import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import '../utils/il8n';

const Header = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <img onClick={() => navigate("/")} style={{ width: 100, marginBottom: '16px', cursor: "pointer" }} src="/logo512.png" alt="Logo" />
            <Typography component="p" variant="subtitle2" sx={{ mb: 4, fontWeight: '800' }}>
                {t('marketing_desc')}
            </Typography>
        </Box>
    )
}

export default Header;