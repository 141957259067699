import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "enter_license_key": "Enter License Key",
            "license_key": "License Key",
            "email_address": "Email Address",
            "submit": "Submit",
            "get_license": "Get License",
            "marketing_desc": "Our tool is designed to simplify your email marketing efforts. Simply upload an Excel sheet of your email contacts (sample is attached on the next page), draft your message, and let us handle the rest. Emails will be delivered in a transactional manner, directly to primary inboxes, hence increasing the likelihood of engagement and responses.",
            "smtp_config": "SMTP Configuration",
            "smtp_host": "SMTP Host",
            "smtp_port": "SMTP Port",
            "email_user": "Email User",
            "email_placeholder": "e.g. your-email@example.com",
            "password": "Password",
            "from": "From",
            "from_hint": "The starting range of emails",
            "to": "To",
            "to_hint": "The ending range of emails",
            "email_template": "Email Template",
            "email_subject": "Email Subject",
            "editor_placeholder": "Write your email body here...",
            "upload_recipients": "Upload Recipients",
            "bold": "Bold",
            "italic": "Italic",
            "underline": "Underline",
            "strikethrough": "Strikethrough",
            "bullet_list": "Bullet List",
            "numbered_list": "Numbered List",
            "insert_link": "Insert Link",
            "clear_formatting": "Clear Formatting",
            "file_upload_success": "File uploaded successfully!",
            "fill_all_smtp_fields": "Please fill in all SMTP fields.",
            "fill_email_subject_body": "Please fill in both email subject and body.",
            "upload_excel_file": "Please upload an Excel file with recipient email addresses.",
            "process_duration": "This process may take a while, please be patient.",
            "upload_file": "Upload File",
            "file_uploaded": "Uploaded",
            "download_sample_file": "Download Sample File",
            "send_emails": "Send Emails",
            "processing_time": "It will take 5-10 minutes depending upon your email list size.",
            "language_toggle": "한국어",
            "back": "BACK",
            "payment": "Payment",
            "payment_description": "One-time payment of $3. Enjoy lifetime access!",
            "name_label": "Name",
            "email_label": "Email",
            "pay_button": "Pay $3",
            "processing": "Processing...",
            "tutorial_video":"Tutorial Video"
        }
    },
    ko: {
        translation: {
            "enter_license_key": "라이센스 키 입력",
            "license_key": "라이센스 키",
            "email_address": "이메일 주소",
            "submit": "제출",
            "get_license": "라이센스 받기",
            "marketing_desc": "우리 도구는 이메일 마케팅 노력을 간소화하도록 설계되었습니다. 이메일 연락처의 Excel 시트를 업로드하고(다음 페이지에 샘플이 첨부되어 있습니다), 메시지를 작성한 다음 나머지 작업을 처리하도록 하십시오. 이메일은 트랜잭션 방식으로 기본 받은 편지함으로 직접 전송되어 참여와 응답 가능성을 높입니다.",
            "smtp_config": "SMTP 구성",
            "smtp_host": "SMTP 호스트",
            "smtp_port": "SMTP 포트",
            "email_user": "이메일",
            "email_placeholder": "예: your-email@example.com",
            "password": "비밀번호",
            "from": "시작",
            "from_hint": "이메일 시작 범위",
            "to": "종료",
            "to_hint": "이메일 종료 범위",
            "email_template": "이메일 템플릿",
            "email_subject": "이메일 제목",
            "editor_placeholder": "여기에 이메일 내용을 입력하세요...",
            "upload_recipients": "수신자 목록 업로드",
            "bold": "굵게",
            "italic": "기울임꼴",
            "underline": "밑줄",
            "strikethrough": "취소선",
            "bullet_list": "글머리 기호 목록",
            "numbered_list": "번호 목록",
            "insert_link": "링크 삽입",
            "clear_formatting": "서식 지우기",
            "file_upload_success": "파일이 성공적으로 업로드되었습니다!",
            "fill_all_smtp_fields": "모든 SMTP 구성 필드를 작성해 주세요.",
            "fill_email_subject_body": "이메일 제목과 내용을 작성해 주세요.",
            "upload_excel_file": "수신자의 이메일 주소가 포함된 Excel 파일을 업로드해 주세요.",
            "process_duration": "이 프로세스에는 시간이 걸릴 수 있으니, 잠시만 기다려 주세요.",
            "upload_file": "파일 업로드",
            "file_uploaded": "업로드 완료",
            "download_sample_file": "샘플 파일 다운로드",
            "send_emails": "이메일 전송",
            "processing_time": "이메일 목록 크기에 따라 5-10분 정도 소요됩니다.",
            "language_toggle": "English",
            "back": "뒤로 가기",
            "payment": "결제",
            "payment_description": "$3의 일회성 결제. 평생 접근 가능!",
            "name_label": "이름",
            "email_label": "이메일",
            "pay_button": "$3 결제",
            "processing": "처리 중...",
            "tutorial_video":"튜토리얼 비디오"
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
