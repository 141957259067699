import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert, Grid } from '@mui/material';
import Header from '../component/head';
import '../utils/il8n'; // Import the i18n configuration
import { useTranslation } from 'react-i18next';


const Login: React.FC = () => {
    const [licenseKey, setLicenseKey] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        try {
            axios.post("https://licensegen.daddyhelp.me/validate_license", {
                license_key: licenseKey,
                email: email
            }).then(() => {
                localStorage.setItem('saas-email', "true")
                // Redirect to the other screen
                navigate("/emails");
            }).catch(({ response }) => {
                setError(response.data.detail);
            })
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        const isLogin = localStorage.getItem("saas-email");
        if (isLogin === "true") {
            navigate("/emails");
        }
    }, []);

    return (
        <Container maxWidth="xs">
           <Grid display={'flex'} justifyContent={'end'} alignItems={'self-end'}>
                <Button
                    onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'ko' : 'en')}
                    // fullWidth
                    variant="text"
                    sx={{ mt: 2 }}
                >
                    {i18n.language === 'en' ? '한국어' : 'English'}
                </Button>
            </Grid>
            <Header />

            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#f7f7f7',
                    padding: '24px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    {t('enter_license_key')}
                </Typography>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="licenseKey"
                    label={t('license_key')}
                    name="licenseKey"
                    autoFocus
                    value={licenseKey}
                    onChange={(e) => setLicenseKey(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('email_address')}
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                />
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {t('submit')}
                </Button>
                <Button
                    onClick={() => navigate("/payment")}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                >
                    {t('get_license')}
                </Button>
            </Box>
        </Container>
    );
};

export default Login;
