import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';

// Make sure to replace this with your own publishable key
const stripePromise = loadStripe('pk_live_51NeN68CEDzUkS6E0zEtL9zIL71u82JrfOl7k15KCmABifSSsW2h4AxjY0LuF7BrGRxHz9fYKCRm9ySkhO2ojDIlA00ChexM7CH');

const Payment: React.FC = () => {

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );
};

export default Payment;
