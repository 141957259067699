import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Container, Typography, Box, TextField, IconButton, Grid } from '@mui/material';
import { StripeCardElement } from '@stripe/stripe-js';
import axios from 'axios';
import Header from '../../component/head';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import i18n from '../../utils/il8n';
import { useTranslation } from 'react-i18next';

const PaymentForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const { t, i18n } = useTranslation();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!name || !email) {
            alert("Please fill out all required fields: Name and Email.");
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch('https://bulkemailserver.daddyhelp.me/create-payment-intent', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount: 200 }), // Example amount in cents (2 dollars)
            });
            const { clientSecret } = await response.json();

            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement) as StripeCardElement,
                    billing_details: {
                        name: name,
                        email: email
                    }
                },
            });

            if (stripeError) {
                setError(stripeError.message || 'An unknown error occurred.');
            } else if (paymentIntent?.status === 'succeeded') {
                await axios.post("https://bulkemailserver.daddyhelp.me/success", { name, email })
                alert('Payment successful! We will send you License Key via email. In case of any query email us at "furqan@transpify.com"');
                setName('');
                setEmail('');
                elements.getElement(CardElement)?.clear();
            }
        } catch (err) {
            setError('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    return (
        <Container component="main" maxWidth="xs">
            <Grid display={'flex'} justifyContent={'end'} alignItems={'self-end'}>
                <Button
                    onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'ko' : 'en')}
                    variant="text"
                    sx={{ mt: 2 }}
                >
                    {t('language_toggle')}
                </Button>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="button" onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
                    {t('back')}
                </Typography>
            </Box>

            <Header />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4,
                }}
            >
                <Typography variant="h5">{t('payment')}</Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                    {t('payment_description')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <TextField
                        label={t('name_label')}
                        variant="outlined"
                        fullWidth
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label={t('email_label')}
                        variant="outlined"
                        type="email"
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Box
                        sx={{
                            border: '1px solid #c4c4c4',
                            borderRadius: '4px',
                            padding: '10px',
                            mt: 2,
                            mb: 2,
                        }}
                    >
                        <CardElement options={cardElementOptions} />
                    </Box>
                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? t('processing') : t('pay_button')}
                    </Button>
                </Box>
            </Box>
        </Container>

    );
};

export default PaymentForm;
